export default function () {
  const { y } = useWindowScroll()

  // const top = useState('top', () => y.value <= 80)

  // watch(y, value => {
  //   top.value = value <= 80
  // })

  const top = computed(() => y.value < 80)

  return top
}
